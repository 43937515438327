// Configure regeneratorRuntime first, so we don't error out
import './runtimeConfig'

// polyfills
import 'babel-polyfill'
import 'core-js/features/promise'

import React from 'react'
import ReactDOM from 'react-dom'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'

import { unregister } from './registerServiceWorker'

import App from './components/App'

async function main() {
  const LaunchDarklyProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    user: {
      key: 'pre-init-fixed-key',
    },
  })

  ReactDOM.render(
    <LaunchDarklyProvider>
      <App />
    </LaunchDarklyProvider>,
    document.getElementById('root')
  )
  unregister()
}

main()
