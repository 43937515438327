import React, { Component } from 'react'
import { loadLibraries } from '../utils/libraries'

export default class LibraryLoaderWrapper extends Component {
  render() {
    let { app, onLoad } = this.props

    if (!app) {
      return null
    }

    return <LibraryLoader key={app.id} app={app} onLoad={onLoad} />
  }
}

class LibraryLoader extends Component {
  notifyLoaded = appId => {
    let { app, onLoad } = this.props

    if (appId !== app.id) {
      return
    }

    onLoad()
  }

  componentDidMount() {
    let { app } = this.props

    loadLibraries(app).then(() => this.notifyLoaded(app.id))
  }

  render() {
    return null
  }
}
