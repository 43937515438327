import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import windowSize from 'react-window-size'
import Favicon from 'react-favicon'
import DocumentTitle from 'react-document-title'

import { fetchApp, fetchAppId, getAppIconUrl } from '../../utils/io'
import { MIN_SCREEN_WIDTH_SAFE_FOR_FRAME } from '../../utils/frame'

import Loading from '../Shared/Loading'
import Renderer from '../Renderer'
import Frame from './Frame'
import { WEB, DEFAULT_SIZE } from './SizeMenu'
import AppDetails from './AppDetails'
import Attribution from './Attribution'
import NotFound from './NotFound'
import Stripe from './Stripe'

import './Share.css'
import './WrappedSelect.css'

class Preview extends Component {
  state = {
    size: null,
    app: null,
  }

  requestApp = async () => {
    let { match } = this.props

    let { appId: path } = match.params
    let host = window.location.host

    try {
      const appId = await fetchAppId(host, path)
      const app = await fetchApp(appId)
      this.setState({ app })
    } catch (error) {
      console.error('ERROR GETTING APP ID', error)
      this.setState({ error: true })
    }
  }

  componentDidMount() {
    this.requestApp()
  }

  render() {
    let { match, windowWidth } = this.props
    let { app, size, error } = this.state

    if (error) {
      return <NotFound />
    }

    if (!app) {
      return <Loading expanded />
    }

    let appId = app.id

    // Attribution is shown in AppFooter if cloning is enabled
    const cloningEnabled = app?.visibility
    const appFooterHidden =
      !cloningEnabled || windowWidth < MIN_SCREEN_WIDTH_SAFE_FOR_FRAME
    const showAdaloAttribution = getShowAdaloAttribution(app, appFooterHidden)

    // let includeWeb = false
    const previewType = getPreviewType(app)

    if (previewType === 'web') {
      // includeWeb = true
      size = size || WEB
    }

    if (size === WEB) {
      const offsetTop = appFooterHidden ? 0 : 74

      return (
        <div>
          <Favicon url={`${getAppIconUrl(appId)}?size=32&radius=7`} />
          <div className="web-renderer-wrapper">
            <Renderer match={match} offsetTop={offsetTop} />
          </div>
          {showAdaloAttribution && <Attribution />}
        </div>
      )
    }

    if (windowWidth < MIN_SCREEN_WIDTH_SAFE_FOR_FRAME) {
      return (
        <Renderer analytics match={match} hideAppFooter size={DEFAULT_SIZE} />
      )
    }

    size = size || DEFAULT_SIZE

    return (
      <React.Fragment>
        <DocumentTitle title={app.name} />
        <Stripe branding={app.branding} className="top-stripe" />
        <Favicon url={`${getAppIconUrl(appId)}?size=32&radius=7`} />
        <div className="preview">
          <AppDetails app={app} />
          <Frame appId={appId} size={size} />
        </div>
        {showAdaloAttribution && <Attribution />}
      </React.Fragment>
    )
  }
}

const hasWebSettings = app => Boolean(app.magicLayout && app.webSettings)

const isValidPreviewType = previewType =>
  ['mobile', 'web'].includes(previewType)

const getPreviewType = app => {
  const { webSettings, primaryPlatform } = app

  if (!hasWebSettings(app)) {
    // Legacy apps
    return primaryPlatform
  }

  const settingsPreviewType = webSettings?.previewType
  // Default to web when invalid/unset
  const previewType = isValidPreviewType(settingsPreviewType)
    ? settingsPreviewType
    : 'web'

  return previewType
}

const getShowAdaloAttribution = (app, appFooterHidden) => {
  let { showBranding, primaryPlatform } = app

  const showAttributionOnMobile = showBranding ?? true
  const showAttributionWebSetting =
    app?.webSettings?.showAdaloAttribution ?? false

  // Mobile apps keep using legacy 'showBranding' property
  const showAdaloAttribution =
    primaryPlatform === 'mobile'
      ? showAttributionOnMobile
      : showAttributionWebSetting

  return showAdaloAttribution && appFooterHidden
}

export default withRouter(windowSize(Preview))
