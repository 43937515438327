import axios from 'axios'

const baseURL = process.env.REACT_APP_COMPONENT_URL
const marketplaceURL = process.env.REACT_APP_COMPONENT_MARKETPLACE
const cache = {}

export const getLibraryDependencies = async app => {
  let { librariesUsed, OrganizationId, id: appId } = app

  let names = [...librariesUsed]
  names = JSON.stringify(names)
  names = encodeURIComponent(names)

  const url = `${marketplaceURL}/api/libraries`
  const { data: marketplaceLibrariesUsed } = await axios.get(url, {
    params: { names, orgId: OrganizationId, appId },
  })

  let DEFAULT_LIBRARIES = [
    { name: '@protonapp/material-components', version: '0.0.40' },
  ]

  let libraries = [...marketplaceLibrariesUsed]
  DEFAULT_LIBRARIES.forEach(lib => {
    if (!libraries.find(lib2 => lib.name === lib2.name)) {
      libraries.push(lib)
    }
  })

  return libraries
}

// Returns promise
export const loadLibrary = (name, version) => {
  let url = `${baseURL}/${name}/${version}/runtime-${version}.js`

  // Check if already in cache
  if (cache[url]) {
    return cache[url]
  }

  let script = document.createElement('script')
  script.src = url
  script.setAttribute('crossorigin', '')
  document.body.appendChild(script)

  let promise = new Promise((resolve, reject) => {
    script.addEventListener('load', () => {
      resolve()
    })

    script.addEventListener('error', () => {
      reject(new Error(`Script could not load: ${url}`))
    })
  })

  cache[url] = promise

  return promise
}

export const loadLibraries = async app => {
  const libraryDependencies = await getLibraryDependencies(app)
  let promises = libraryDependencies.map(({ name, version }) => {
    return loadLibrary(name, version)
  })

  return Promise.allSettled(promises)
}

export const getLibraries = app => {
  return window.protonRuntime || {}
}
