import React, { Component } from 'react'
import classNames from 'classnames'

import AppIcon from '../Share/Icon'
import './IOSInstallPrompt.css'
import ShareButton from './share.svg'

const isInStandaloneMode = () =>
  window.matchMedia('(display-mode: standalone)').matches ||
  window.navigator.standalone ||
  document.referrer.includes('android-app://')

const isIOSSafari = () => {
  let ios = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

  return ios && !isInStandaloneMode()
}

export default class IOSInstallPrompt extends Component {
  state = { hidden: false }

  handleClose = () => {
    this.setState({ hidden: true })
  }

  render() {
    let { hidden } = this.state
    let { app } = this.props

    let showAddToHomeScreen = app?.webSettings?.showAddToHomeScreen ?? false

    if (!app?.magicLayout && app?.primaryPlatform === 'mobile') {
      // Legacy mobile apps always show the prompt
      showAddToHomeScreen = true
    } else if (!app?.magicLayout && app?.primaryPlatform === 'web') {
      // Desktop web apps never show the prompt
      showAddToHomeScreen = false
    }

    if (!isIOSSafari() || !app || !showAddToHomeScreen) {
      return null
    }

    let { id, name } = app

    return (
      <div className={classNames('ios-install-prompt-wrapper', { hidden })}>
        <div className="ios-install-prompt-modal">
          <div className="ios-install-prompt-close" onClick={this.handleClose}>
            ×
          </div>
          <div className="ios-install-prompt-content">
            <AppIcon appId={id} name={name} />
            <h1>Install {name}</h1>
            <p>
              Install this application on your home screen to easily access it
              on the go.
            </p>
          </div>
          <div className="ios-install-prompt-cta">
            Just tap
            <img src={ShareButton} width={19} height={25} alt="share" />
            then "Add to Home Screen"
          </div>
        </div>
      </div>
    )
  }
}
