import { useEffect } from 'react'
import { useLDClient } from 'launchdarkly-react-client-sdk'

const FeatureFlagIdentitySync = ({ app }) => {
  const client = useLDClient()

  const { flagUser } = app || {}

  useEffect(() => {
    if (!client || !app) {
      return
    }

    client.identify(flagUser)
  }, [client, app, flagUser])

  return null
}

export default FeatureFlagIdentitySync
