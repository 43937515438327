import React from 'react'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import windowSize from 'react-window-size'

import { FRONTEND_URL } from '../config'
import { isInIframe, MIN_SCREEN_WIDTH_SAFE_FOR_FRAME } from '../utils/frame'

function AppFooter(props) {
  const { app, flags, windowWidth } = props

  if (!app || windowWidth < MIN_SCREEN_WIDTH_SAFE_FOR_FRAME) {
    return null
  }

  const cloningEnabled = app?.visibility
  const visible =
    cloningEnabled ||
    (flags?.hasFreePlanWebRuntimeBanner &&
      app.Organization?.planType === 'free')

  // Don't show AppFooter when embedded in an iFrame
  const inIframe = isInIframe()

  if (!visible || inIframe) return null

  let cloneURL = `${FRONTEND_URL}/apps/${app.id}/screens/${app.visibility}`

  return (
    <div className="web-runtime-footer">
      <div>
        {cloningEnabled && (
          <div className="details-footer-clone-button-wrapper">
            <a className="details-footer-clone-button" href={cloneURL}>
              Clone App
            </a>
          </div>
        )}
      </div>
      <a
        href="https://adalo.com"
        target="_blank"
        rel="noopener noreferrer"
        className="preview-proton-attribution"
      >
        <span className="logo" alt="Adalo" />
        <h6>Created with Adalo</h6>
      </a>
    </div>
  )
}

export default withLDConsumer()(windowSize(AppFooter))
