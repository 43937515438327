import React from 'react'
import QRCodeReact from 'qrcode.react'

import Button from '../../Shared/Button'

const QRCode = ({ url, handleToggleModal, handleCopyURL }) => {
  const renderSMSLink = () => (
    <Button
      onClick={handleToggleModal}
      title="open a modal to send the share page url via sms"
      content="send the link via sms"
      text
    />
  )

  const renderCopyLink = () => (
    <Button
      onClick={handleCopyURL}
      title="copy the share page url to clipboard"
      content="copy the link here."
      text
    />
  )

  return (
    <div className="qrcode-wrapper">
      <QRCodeReact className="qrcode" value={url} size={156} />
      <section className="qrcode-body">
        <h2>Scan to Install</h2>
        <p>
          *If your camera doesn&apos;t have a QR code scanner, <br />
          you can {renderSMSLink()} or {renderCopyLink()}
        </p>
      </section>
    </div>
  )
}

export default QRCode
