import React, { Component, Fragment } from 'react'
import qs from 'qs'
import DocumentTitle from 'react-document-title'
import Favicon from 'react-favicon'
import Helmet from 'react-helmet'
import SafeAreaInsets from 'safe-area-insets'
import StatusBar from '@protonapp/react-status-bar'
import { isInIframe } from '../utils/frame'

import {
  DATABASE_URL,
  DATABASE_API_URL,
  ASSETS_URL,
  FILE_UPLOADS_URL,
  IMAGE_UPLOADS_URL,
  NOTIFICATIONS_URL,
} from '../config'

import { getAppIconUrl, fetchApp, fetchAppId } from '../utils/io'
import { getLibraries } from '../utils/libraries'
import FeatureFlagIdentitySync from '../flags/FeatureFlagIdentitySync'

import NotFound from './Share/NotFound'
import LibraryLoader from './LibraryLoader'
import AppFooter from './AppFooter'
import Runner from './RunnerWrapper'
import IOSInstallPrompt from './IOSInstallPrompt'

import '@protonapp/react-status-bar/styles.css'
import './Renderer.css'

export default class Renderer extends Component {
  state = {
    app: null,
    authenticationError: false,
    librariesReady: false,
    currentComponentId: null,
  }

  requestApp = async () => {
    const { match } = this.props

    const { appId: path } = match.params
    const host = window.location.host

    try {
      const appId = await fetchAppId(host, path)
      const app = await fetchApp(appId)
      this.setState({ app })
    } catch (error) {
      console.error('ERROR GETTING APP ID', error)
      this.setState({ error: true })
    }
  }

  receiveApp = app => {
    this.setState({ app })
  }

  handleLoadLibraries = () => {
    this.setState({ librariesReady: true })
  }

  handleUnauthorized = () => {
    this.setState({ authenticationError: true })
  }

  getParams = () => {
    return qs.parse(window.location.search.substring(1))
  }

  getOS = () => {
    return this.getParams().os
  }

  getLayoutGuides = () => {
    const { size } = this.props
    let params = isInIframe() ? size : this.getParams()

    if (SafeAreaInsets.top > 0) {
      return { top: SafeAreaInsets.top, bottom: 0 }
    }

    const top = +params.offset_top || 0
    const bottom = +params.offset_bottom || 0

    return { top, bottom }
  }

  componentWillMount() {
    this.requestApp()
  }

  handleRouteChange = newRoute => {
    this.setState({ currentComponentId: newRoute.componentId })
  }

  renderStatusBar() {
    let { app, currentComponentId } = this.state

    if (!isInIframe()) {
      return null
    }

    if (!app || !app.components) {
      return null
    }

    let component = app.components[currentComponentId]
    let layoutGuides = this.getLayoutGuides()

    if (!component) {
      return null
    }

    let { statusBarStyle } = component
    let light = false
    let hasNotch = layoutGuides.top > 20

    if (statusBarStyle === 'hidden') {
      return null
    }

    if (statusBarStyle === 'light') {
      light = true
    }

    return (
      <div className="status-bar-wrapper">
        <StatusBar platform={this.getOS()} light={light} hasNotch={hasNotch} />
      </div>
    )
  }

  render() {
    const { app, librariesReady, authenticationError, error } = this.state

    const { offsetTop, hideAppFooter } = this.props

    if (error) {
      return <NotFound />
    }

    if (authenticationError) {
      return (
        <div>
          <h1>Unauthorized</h1>
        </div>
      )
    }

    const layoutGuides = this.getLayoutGuides()
    const libraries = getLibraries(app)

    return (
      <Fragment>
        <FeatureFlagIdentitySync app={app} />
        {app && (
          <Fragment>
            <DocumentTitle title={app.name} />
            <Favicon url={getAppIconUrl(app.id, 32, 7)} />
            <Helmet>
              <link rel="shortcut icon" href={getAppIconUrl(app.id, 180, 7)} />
              <link
                rel="apple-touch-icon"
                sizes="180x180"
                href={getAppIconUrl(app.id, 180, 7)}
              />
            </Helmet>
          </Fragment>
        )}
        <LibraryLoader app={app} onLoad={this.handleLoadLibraries} />
        <Runner
          app={librariesReady ? app : null}
          baseURL={DATABASE_URL}
          baseAPIURL={DATABASE_API_URL}
          assetsBaseURL={ASSETS_URL}
          fileUploadsBaseURL={FILE_UPLOADS_URL}
          imageUploadsBaseURL={IMAGE_UPLOADS_URL}
          notificationsURL={NOTIFICATIONS_URL}
          libraries={libraries}
          offsetTop={offsetTop}
          transitionStyle={this.getOS()}
          layoutGuides={layoutGuides}
          onRoute={this.handleRouteChange}
        />
        {app ? this.renderStatusBar() : null}
        {!hideAppFooter && <AppFooter app={app} />}

        <IOSInstallPrompt app={app} />
      </Fragment>
    )
  }
}
