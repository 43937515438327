import React, { Component } from 'react'
import DocumentEvents from 'react-document-events'
import classNames from 'classnames'

export default class Frame extends Component {
  getScale = () => {
    let { size } = this.props

    let { height } = size
    let margin = 30
    let windowHeight = Math.max(window.innerHeight, 400)
    let yPad = this.hasNotch() ? 20 : 90

    return (windowHeight - 2 * margin) / (height + 2 * yPad)
  }

  forceRender = () => {
    this.forceUpdate()
  }

  getBaseURL = () => {
    return `/preview`
  }

  hasNotch = () => {
    let { size } = this.props
    let { offset_top: offsetTop, os } = size

    return os === 'ios' && offsetTop > 20
  }

  getURL = () => {
    // Same url as parent
    return window.location.href
  }

  render() {
    let { size } = this.props
    let { width, height } = size
    let iphonex = this.hasNotch()
    let src = this.getURL()
    let scale = this.getScale()

    let styles = {
      transform: `scale(${scale})`,
      width: width + 40,
      height: height + (iphonex ? 40 : 180),
    }

    let innerStyles = {
      width: Math.ceil(scale * styles.width),
      height: Math.ceil(scale * styles.height),
    }

    return (
      <div className="preview-frame-wrapper">
        <DocumentEvents target={window} onResize={this.forceRender} />
        <div className="preview-frame-inner-wrapper" style={innerStyles}>
          <div
            className={classNames('preview-frame-border', { iphonex })}
            style={styles}
          >
            <div className="preview-frame-clip">
              {[
                <iframe
                  title="Frame"
                  allowFullScreen
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  key={src}
                  src={src}
                  width={width}
                  height={height}
                  className="preview-frame"
                  allow="camera *; microphone *"
                />,
              ]}
            </div>
            <div className="preview-frame-cover" />
          </div>
        </div>
      </div>
    )
  }
}
