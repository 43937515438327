import axios from 'axios'
import { BACKEND_URL } from '../config'

export const getAppUrl = appId => `${BACKEND_URL}/apps/${appId}/clients/runner`

export const getAppIconUrl = (appId, size, radius) =>
  `${BACKEND_URL}/apps/${appId}/icon?size=${size}&radius=${radius}`

export const getAppIdUrl = (host, path) => {
  host = encodeURIComponent(host)
  path = path || ''

  return `${BACKEND_URL}/app-search?host=${host}&path=${path}`
}

const backendClient = axios.create({
  headers: { 'x-adalo-client': 'web-runtime' },
})

export const fetchAppId = async (host, path) => {
  const appIdUrl = getAppIdUrl(host, path)
  const { data } = await backendClient.get(appIdUrl)
  const { id: appId } = data || {}
  return appId
}

export const fetchApp = async appId => {
  const appUrl = getAppUrl(appId)
  const { data } = await backendClient.get(appUrl)
  return data
}
